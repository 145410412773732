<template>
    <div>
        <v-toolbar
                dense
                tabs
                color="primary darken-2"
                dark
        >
            <v-toolbar-title class="pl-2">Signup</v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md8 offset-md2>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <v-card class="blue lighten-4" style="" >
                                <v-card-text style="">
                                    <p class="dark--text adjust-text-size pa-0 ma-0 text-xs-center"><span> Hoooraaay!!!!!!!!!!!!, your account has been fully created. You have gained <b class="success--text">50 credit points</b></span></p>
                                </v-card-text>
                            </v-card>

                            <v-card color="white pa-3 " style="background-color: rgb(236, 243, 250)!important" >
                                <b><u>Step 4/4: Final verification</u></b>
                                <v-layout row>
                                    <v-flex xs12 md10 offset-md1>            
                                        <p class="ma-0 mt-1"><b>Username: </b> Yuyen Kiniben T. <i class="fas fa-edit red--text" style="cursor: pointer"></i></p>
                                        <p class="ma-0 mt-1"><b>Phone number: </b> +237 6xxxxxxxx <i class="fas fa-edit red--text" style="cursor: pointer"></i></p>
                                        <p class="ma-0 mt-1"><b>Gender: </b> Male <i class="fas fa-edit red--text" style="cursor: pointer"></i></p>
                                        <p class="ma-0 mt-1"><b>Profession: </b> Student <i class="fas fa-edit red--text" style="cursor: pointer"></i></p>
                                        <p class="ma-0 mt-1"><b>Email: </b> yuyentanlaka@gmail.com <i class="fas fa-edit red--text" style="cursor: pointer"></i></p>
                                        <p class="ma-0 mt-1"><b>Address: </b> Ndamukong Street, Bamenda <i class="fas fa-edit red--text" style="cursor: pointer"></i></p>
                                    </v-flex>
                                </v-layout>
                                <div class=" text-xs-right">
                                    <v-btn class="white--text elevation-6" round color="teal darken-2" to="/">
                                        <span> Get Started</span>
                                    </v-btn>
                                </div>
                            </v-card>

                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                select: null,
                items: [
                    'Male',
                    'Female',
                ],
            }
        }

    }
</script>
<style >
    @media (max-width: 499px) {
        .adjust-text-size{
            font-size: 12px
        }
    }
    @media (min-width: 499px) {
        .adjust-text-size{
            font-size: 14px
        }
    }
</style>
